import { noop, addEventListener, elapsed, relativeNow, addMonitoringMessage, toServerDuration } from '@datadog/browser-core';
// Arbitrary value to cap number of element (mostly for backend)
export var MAX_NUMBER_OF_FOCUSED_TIME = 500;
var foregroundPeriods = [];
export function startForegroundContexts(configuration) {
  if (!configuration.isEnabled('track-foreground')) {
    return {
      getInForeground: function () {
        return undefined;
      },
      getInForegroundPeriods: function () {
        return undefined;
      },
      stop: noop
    };
  }
  if (document.hasFocus()) {
    addNewForegroundPeriod();
  }
  var stopForegroundTracking = trackFocus(addNewForegroundPeriod).stop;
  var stopBlurTracking = trackBlur(closeForegroundPeriod).stop;
  return {
    getInForeground: getInForeground,
    getInForegroundPeriods: getInForegroundPeriods,
    stop: function () {
      foregroundPeriods = [];
      stopForegroundTracking();
      stopBlurTracking();
    }
  };
}
function addNewForegroundPeriod() {
  if (foregroundPeriods.length > MAX_NUMBER_OF_FOCUSED_TIME) {
    addMonitoringMessage('Reached maximum of foreground time');
    return;
  }
  var currentForegroundPeriod = foregroundPeriods[foregroundPeriods.length - 1];
  if (currentForegroundPeriod !== undefined && currentForegroundPeriod.end === undefined) {
    addMonitoringMessage('Previous foreground periods not closed. Continuing current one', {
      inForegroundPeriodsCount: foregroundPeriods.length,
      currentForegroundPeriodStart: currentForegroundPeriod.start
    });
    return;
  }
  foregroundPeriods.push({
    start: relativeNow()
  });
}
function closeForegroundPeriod() {
  if (foregroundPeriods.length === 0) {
    addMonitoringMessage('No foreground period');
    return;
  }
  var currentForegroundPeriod = foregroundPeriods[foregroundPeriods.length - 1];
  if (currentForegroundPeriod.end !== undefined) {
    addMonitoringMessage('Current foreground period already closed', {
      inForegroundPeriodsCount: foregroundPeriods.length,
      currentForegroundPeriodStart: currentForegroundPeriod.start,
      currentForegroundPeriodEnd: currentForegroundPeriod.end
    });
    return;
  }
  currentForegroundPeriod.end = relativeNow();
}
function trackFocus(onFocusChange) {
  return addEventListener(window, "focus" /* FOCUS */, function () {
    return onFocusChange();
  });
}
function trackBlur(onBlurChange) {
  return addEventListener(window, "blur" /* BLUR */, function () {
    return onBlurChange();
  });
}
function getInForeground(startTime) {
  for (var i = foregroundPeriods.length - 1; i >= 0; i--) {
    var foregroundPeriod = foregroundPeriods[i];
    if (foregroundPeriod.end !== undefined && startTime > foregroundPeriod.end) {
      break;
    }
    if (startTime > foregroundPeriod.start && (foregroundPeriod.end === undefined || startTime < foregroundPeriod.end)) {
      return true;
    }
  }
  return false;
}
function getInForegroundPeriods(eventStartTime, duration) {
  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  var eventEndTime = eventStartTime + duration;
  var filteredForegroundPeriods = [];
  for (var i = foregroundPeriods.length - 1; i >= 0; i--) {
    var foregroundPeriod = foregroundPeriods[i];
    if (foregroundPeriod.end !== undefined && eventStartTime > foregroundPeriod.end) {
      // event starts after the end of the current focus period
      // since the array is sorted, we can stop looking for foreground periods
      break;
    }
    if (eventEndTime < foregroundPeriod.start) {
      // event ends before the start of the current focus period
      // continue to previous one
      continue;
    }
    var startTime = eventStartTime > foregroundPeriod.start ? eventStartTime : foregroundPeriod.start;
    var startDuration = elapsed(eventStartTime, startTime);
    var endTime = foregroundPeriod.end === undefined || eventEndTime < foregroundPeriod.end ? eventEndTime : foregroundPeriod.end;
    var endDuration = elapsed(startTime, endTime);
    filteredForegroundPeriods.unshift({
      start: toServerDuration(startDuration),
      duration: toServerDuration(endDuration)
    });
  }
  return filteredForegroundPeriods;
}
