import { noop, elapsed, round, timeStampNow } from '@datadog/browser-core';
import { supportPerformanceTimingEvent } from '../../../browser/performanceCollection';
import { ViewLoadingType } from '../../../rawRumEvent.types';
import { LifeCycleEventType } from '../../lifeCycle';
import { trackEventCounts } from '../../trackEventCounts';
import { waitIdlePageActivity } from '../../trackPageActivities';
export function trackViewMetrics(lifeCycle, domMutationObservable, scheduleViewUpdate, loadingType) {
  var viewMetrics = {
    eventCounts: {
      errorCount: 0,
      longTaskCount: 0,
      resourceCount: 0,
      userActionCount: 0
    }
  };
  var stopEventCountsTracking = trackEventCounts(lifeCycle, function (newEventCounts) {
    viewMetrics.eventCounts = newEventCounts;
    scheduleViewUpdate();
  }).stop;
  var _a = trackLoadingTime(loadingType, function (newLoadingTime) {
      viewMetrics.loadingTime = newLoadingTime;
      scheduleViewUpdate();
    }),
    setActivityLoadingTime = _a.setActivityLoadingTime,
    setLoadEvent = _a.setLoadEvent;
  var stopActivityLoadingTimeTracking = trackActivityLoadingTime(lifeCycle, domMutationObservable, setActivityLoadingTime).stop;
  var stopCLSTracking;
  if (isLayoutShiftSupported()) {
    viewMetrics.cumulativeLayoutShift = 0;
    stopCLSTracking = trackLayoutShift(lifeCycle, function (layoutShift) {
      viewMetrics.cumulativeLayoutShift = round(viewMetrics.cumulativeLayoutShift + layoutShift, 4);
      scheduleViewUpdate();
    }).stop;
  } else {
    stopCLSTracking = noop;
  }
  return {
    stop: function () {
      stopEventCountsTracking();
      stopActivityLoadingTimeTracking();
      stopCLSTracking();
    },
    setLoadEvent: setLoadEvent,
    viewMetrics: viewMetrics
  };
}
function trackLoadingTime(loadType, callback) {
  var isWaitingForLoadEvent = loadType === ViewLoadingType.INITIAL_LOAD;
  var isWaitingForActivityLoadingTime = true;
  var loadingTimeCandidates = [];
  function invokeCallbackIfAllCandidatesAreReceived() {
    if (!isWaitingForActivityLoadingTime && !isWaitingForLoadEvent && loadingTimeCandidates.length > 0) {
      callback(Math.max.apply(Math, loadingTimeCandidates));
    }
  }
  return {
    setLoadEvent: function (loadEvent) {
      if (isWaitingForLoadEvent) {
        isWaitingForLoadEvent = false;
        loadingTimeCandidates.push(loadEvent);
        invokeCallbackIfAllCandidatesAreReceived();
      }
    },
    setActivityLoadingTime: function (activityLoadingTime) {
      if (isWaitingForActivityLoadingTime) {
        isWaitingForActivityLoadingTime = false;
        if (activityLoadingTime !== undefined) {
          loadingTimeCandidates.push(activityLoadingTime);
        }
        invokeCallbackIfAllCandidatesAreReceived();
      }
    }
  };
}
function trackActivityLoadingTime(lifeCycle, domMutationObservable, callback) {
  var startTime = timeStampNow();
  var stopWaitIdlePageActivity = waitIdlePageActivity(lifeCycle, domMutationObservable, function (params) {
    if (params.hadActivity) {
      callback(elapsed(startTime, params.endTime));
    } else {
      callback(undefined);
    }
  }).stop;
  return {
    stop: stopWaitIdlePageActivity
  };
}
/**
 * Track layout shifts (LS) occurring during the Views.  This yields multiple values that can be
 * added up to compute the cumulated layout shift (CLS).
 *
 * See isLayoutShiftSupported to check for browser support.
 *
 * Documentation: https://web.dev/cls/
 * Reference implementation: https://github.com/GoogleChrome/web-vitals/blob/master/src/getCLS.ts
 */
function trackLayoutShift(lifeCycle, callback) {
  var stop = lifeCycle.subscribe(LifeCycleEventType.PERFORMANCE_ENTRY_COLLECTED, function (entry) {
    if (entry.entryType === 'layout-shift' && !entry.hadRecentInput) {
      callback(entry.value);
    }
  }).unsubscribe;
  return {
    stop: stop
  };
}
/**
 * Check whether `layout-shift` is supported by the browser.
 */
function isLayoutShiftSupported() {
  return supportPerformanceTimingEvent('layout-shift');
}
