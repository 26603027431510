import { Component, OnInit } from '@angular/core';
import {
  AuthenticationService,
  GoogleAnalyticsService,
  RedirectService,
  SpinnerService,
} from '@wilson/wilsonng';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { FunnelService } from './services/funnel.service';
import { AppInitService } from './services/app-init.service';
import { UserService } from './services/user.service';
import mixpanel from 'mixpanel-browser';
import { InitialLoadService } from './services/initial-load.service';
import { Wilson } from 'src/def/Wilson';
import ProgramResource = Wilson.ProgramResource;
import User = Wilson.User;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  funnelsLoaded = false;
  isLoading = false;
  private redirectCookieName = 'communitiesRedirect';

  constructor(
    private funnelService: FunnelService,
    private userService: UserService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private appInitService: AppInitService,
    public authenticationService: AuthenticationService,
    private spinnerService: SpinnerService,
    private redirectService: RedirectService,
    private initialLoadService: InitialLoadService
  ) {}

  ngOnInit(): void {
    this.parseQueryString();
    this.setMixPanelKeyAndInitialize();
    this.loadFunnelsAndUser().subscribe();
    this.appInitService.init();
  }

  parseQueryString(): void {
    const params = new URLSearchParams(window.location.search);
    this.forceReauthDecider(params);
  }

  forceReauthDecider(params: URLSearchParams): void {
    if (params.get('rechallenge') === '1') {
      this.authenticationService.logout();
    }
  }

  loadFunnelsAndUser(): Observable<ProgramResource[]> {
    this.redirectService.setRedirectCookieName(this.redirectCookieName);
    this.spinnerService.show();
    this.isLoading = true;

    let loginTesterIfApplicable = of(null);
    if (!this.authenticationService.isLoggedIn()) {
      // Attempt test mode login.
      // NOTE: Have to use window.location.search here instead of checking ActivatedRoute query params,
      // since appInitService.initRouting() has not been called yet
      if (this.isTestModeLogin()) {
        loginTesterIfApplicable = this.userService.loginTester();
      } else {
        const path = this.getWindow().location.pathname;
        if (!path.includes('/p/') && !path.includes('/funhub')) {
          if (path !== '/') {
            this.redirectService.setRedirectCookie(
              this.getWindow().location.search.split('?')[1]
            );
          }
          this.authenticationService.login();
          return of(null);
        }
      }
    }
    return loginTesterIfApplicable.pipe(
      switchMap(() => this.login()),
      switchMap(() => this.funnelService.loadFunnels()),
      tap(() => {
        // Wait to do any navigation until the funnels have been loaded from the server
        this.funnelsLoaded = true;
        this.appInitService.initRouting();
        this.isLoading = false;
        this.spinnerService.hide();
      }),
      catchError((err) => {
        if (err.status === 401 || err.status === 403) {
          this.funnelsLoaded = true;
          this.isLoading = false;
          this.spinnerService.hide();
        }
        return of(null);
      })
    );
  }
  login(): Observable<User> {
    if (this.authenticationService.isLoggedIn()) {
      this.setGoogleAnalyticsKeyAndInitialize();
      this.registerUserWithGoogleAnalytics();
      this.registerUserWithMixPanel();
      return this.userService.loginAndGetUser();
    }
    return of(null);
  }

  registerUserWithGoogleAnalytics(): void {
    const id = this.authenticationService.currentUser?.wilsonId;
    if (id) {
      this.googleAnalyticsService.setUserId(id);
    }
  }

  /* istanbul ignore next */
  registerUserWithMixPanel(): void {
    const user = this.authenticationService.currentUser;
    if (user) {
      mixpanel.identify(user.email);
      mixpanel.people.set({
        $name: user.name,
        $email: user.email,
        $distinct_id: user.email,
        'Wilson ID': user.wilsonId,
      });

      // do not track test users
      if (user.email === 'uitester@wilsonlanguage.com') {
        mixpanel.opt_out_tracking();
      }

      mixpanel.track('Arrived On Site');
    }
  }

  // Useful for mocking "window" in tests
  getWindow(): Window {
    return window;
  }

  private isTestModeLogin(): boolean {
    return (
      this.getWindow()['Cypress'] &&
      new URLSearchParams(this.getWindow().location.search)?.get('test') ===
        'true'
    );
  }

  //Initializing Analytics after login so that unauthed components will not trigger GA or Mixpanel tracking
  private setGoogleAnalyticsKeyAndInitialize(): void {
    const prodToken = 'G-WCRNM47JVB';
    const devToken = 'G-7EDDL6H3S4';
    const key = this.initialLoadService.initialLoad.isProduction
      ? prodToken
      : devToken;
    this.googleAnalyticsService.setKey(key);
    this.googleAnalyticsService.initialize();
  }

  private setMixPanelKeyAndInitialize(): void {
    const prodToken = 'ba49ee92e74530223c8cb96c2def29ae';
    const devToken = 'd4ad2e94d03d69386a4072dda5221e1d';
    const key = this.initialLoadService.initialLoad.isProduction
      ? prodToken
      : devToken;
    mixpanel.init(key);
    mixpanel.register({ Application: 'Communities' });
  }
}
