import { Injectable } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';
import { InitialLoadService } from './initial-load.service';

@Injectable({
  providedIn: 'root',
})
export class LogService {
  constructor(private initialLoadService: InitialLoadService) {}

  public setupDatadogMonitoring(): void {
    const datadogEnvironment =
      this.initialLoadService.initialLoad.tokens[
        'DATADOG_MONITORING_ENVIRONMENT'
      ];
    const datadogSampleRate =
      this.initialLoadService.initialLoad.tokens['DATADOG_SAMPLE_RATE'];
    if (datadogEnvironment && datadogEnvironment.length) {
      datadogRum.init({
        applicationId: '89053435-bfe3-44f7-ade3-7b845bfbb04f',
        clientToken: 'pubfaaf1ee28e3984d819b4ec986bd2e61f',
        site: 'datadoghq.com',
        service: 'Communities',
        env: datadogEnvironment,
        sampleRate: +datadogSampleRate || 100,
        trackInteractions: true,
      });
    }
  }
}
