import { Directive, OnInit, HostBinding } from '@angular/core';
import { FunnelService } from 'src/app/services/funnel.service';

@Directive({
  selector: '[appThemeChooser]',
})
export class ThemeChooserDirective implements OnInit {
  @HostBinding('class')
  themeClass: string;
  constructor(private funnelService: FunnelService) {}

  ngOnInit(): void {
    this.funnelService.watchCurrentFunnel().subscribe(() => {
      this.updateClass();
    });
  }

  private updateClass() {
    const funnel = this.funnelService.getCurrentFunnel();
    if (!funnel) return;

    this.themeClass = funnel.id;
  }
}
