import { __assign, __spreadArrays } from "tslib";
import { ErrorSource, toStackTraceString, ErrorHandling, createHandlingStack, formatErrorMessage } from '../../tools/error';
import { clocksNow } from '../../tools/timeUtils';
import { find, jsonStringify } from '../../tools/utils';
import { callMonitored } from '../internalMonitoring';
import { computeStackTrace } from '../tracekit';
var originalConsoleError;
/* eslint-disable no-console */
export function trackConsoleError(errorObservable) {
  originalConsoleError = console.error;
  console.error = function () {
    var params = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      params[_i] = arguments[_i];
    }
    var handlingStack = createHandlingStack();
    callMonitored(function () {
      originalConsoleError.apply(console, params);
      errorObservable.notify(__assign(__assign({}, buildErrorFromParams(params, handlingStack)), {
        source: ErrorSource.CONSOLE,
        startClocks: clocksNow(),
        handling: ErrorHandling.HANDLED
      }));
    });
  };
  return {
    stop: function () {
      console.error = originalConsoleError;
    }
  };
}
function buildErrorFromParams(params, handlingStack) {
  var firstErrorParam = find(params, function (param) {
    return param instanceof Error;
  });
  return {
    message: __spreadArrays(['console error:'], params).map(function (param) {
      return formatConsoleParameters(param);
    }).join(' '),
    stack: firstErrorParam ? toStackTraceString(computeStackTrace(firstErrorParam)) : undefined,
    handlingStack: handlingStack
  };
}
function formatConsoleParameters(param) {
  if (typeof param === 'string') {
    return param;
  }
  if (param instanceof Error) {
    return formatErrorMessage(computeStackTrace(param));
  }
  return jsonStringify(param, undefined, 2);
}
