import { BoundedBuffer, buildCookieOptions, checkCookiesAuthorized, checkIsNotLocalFile, createContextManager, deepClone, ErrorSource, isPercentage, makePublicApi, monitor, clocksNow, timeStampNow, display, commonInit, callMonitored, createHandlingStack } from '@datadog/browser-core';
import { ActionType } from '../rawRumEvent.types';
import { buildEnv } from './buildEnv';
export function makeRumPublicApi(startRumImpl) {
  var isAlreadyInitialized = false;
  var globalContextManager = createContextManager();
  var user = {};
  var getInternalContextStrategy = function () {
    return undefined;
  };
  var getInitConfigurationStrategy = function () {
    return undefined;
  };
  var bufferApiCalls = new BoundedBuffer();
  var addTimingStrategy = function (name, time) {
    if (time === void 0) {
      time = timeStampNow();
    }
    bufferApiCalls.add(function () {
      return addTimingStrategy(name, time);
    });
  };
  var startViewStrategy = function (name, startClocks) {
    if (startClocks === void 0) {
      startClocks = clocksNow();
    }
    bufferApiCalls.add(function () {
      return startViewStrategy(name, startClocks);
    });
  };
  var addActionStrategy = function (action, commonContext) {
    if (commonContext === void 0) {
      commonContext = clonedCommonContext();
    }
    bufferApiCalls.add(function () {
      return addActionStrategy(action, commonContext);
    });
  };
  var addErrorStrategy = function (providedError, commonContext) {
    if (commonContext === void 0) {
      commonContext = clonedCommonContext();
    }
    bufferApiCalls.add(function () {
      return addErrorStrategy(providedError, commonContext);
    });
  };
  function clonedCommonContext() {
    return deepClone({
      context: globalContextManager.get(),
      user: user
    });
  }
  function initRum(initConfiguration) {
    if (!checkCookiesAuthorized(buildCookieOptions(initConfiguration)) || !checkIsNotLocalFile() || !canInitRum(initConfiguration)) {
      return;
    }
    if (initConfiguration.publicApiKey) {
      initConfiguration.clientToken = initConfiguration.publicApiKey;
    }
    var _a = commonInit(initConfiguration, buildEnv),
      configuration = _a.configuration,
      internalMonitoring = _a.internalMonitoring;
    if (!configuration.trackViewsManually) {
      doStartRum();
    } else {
      // drain beforeInitCalls by buffering them until we start RUM
      // if we get a startView, drain re-buffered calls before continuing to drain beforeInitCalls
      // in order to ensure that calls are processed in order
      var beforeInitCalls = bufferApiCalls;
      bufferApiCalls = new BoundedBuffer();
      startViewStrategy = function (name) {
        doStartRum(name);
      };
      beforeInitCalls.drain();
    }
    getInitConfigurationStrategy = function () {
      return deepClone(initConfiguration);
    };
    isAlreadyInitialized = true;
    function doStartRum(initialViewName) {
      var _a;
      ;
      _a = startRumImpl(initConfiguration, configuration, internalMonitoring, function () {
        return {
          user: user,
          context: globalContextManager.get()
        };
      }, initialViewName), startViewStrategy = _a.startView, addActionStrategy = _a.addAction, addErrorStrategy = _a.addError, addTimingStrategy = _a.addTiming, getInternalContextStrategy = _a.getInternalContext;
      bufferApiCalls.drain();
    }
  }
  var rumPublicApi = makePublicApi({
    init: monitor(initRum),
    addRumGlobalContext: monitor(globalContextManager.add),
    removeRumGlobalContext: monitor(globalContextManager.remove),
    getRumGlobalContext: monitor(globalContextManager.get),
    setRumGlobalContext: monitor(globalContextManager.set),
    getInternalContext: monitor(function (startTime) {
      return getInternalContextStrategy(startTime);
    }),
    getInitConfiguration: monitor(function () {
      return getInitConfigurationStrategy();
    }),
    addAction: monitor(function (name, context) {
      addActionStrategy({
        name: name,
        context: deepClone(context),
        startClocks: clocksNow(),
        type: ActionType.CUSTOM
      });
    }),
    /**
     * @deprecated use addAction instead
     */
    addUserAction: function (name, context) {
      rumPublicApi.addAction(name, context);
    },
    addError: function (error, context, source) {
      if (source === void 0) {
        source = ErrorSource.CUSTOM;
      }
      var handlingStack = createHandlingStack();
      callMonitored(function () {
        var checkedSource;
        if (source === ErrorSource.CUSTOM || source === ErrorSource.NETWORK || source === ErrorSource.SOURCE) {
          checkedSource = source;
        } else {
          display.error("DD_RUM.addError: Invalid source '" + source + "'");
          checkedSource = ErrorSource.CUSTOM;
        }
        addErrorStrategy({
          error: error,
          handlingStack: handlingStack,
          context: deepClone(context),
          source: checkedSource,
          startClocks: clocksNow()
        });
      });
    },
    addTiming: monitor(function (name) {
      addTimingStrategy(name);
    }),
    setUser: monitor(function (newUser) {
      var sanitizedUser = sanitizeUser(newUser);
      if (sanitizedUser) {
        user = sanitizedUser;
      } else {
        display.error('Unsupported user:', newUser);
      }
    }),
    removeUser: monitor(function () {
      user = {};
    }),
    startView: monitor(function (name) {
      startViewStrategy(name);
    })
  });
  return rumPublicApi;
  function sanitizeUser(newUser) {
    if (typeof newUser !== 'object' || !newUser) {
      return;
    }
    var result = deepClone(newUser);
    if ('id' in result) {
      result.id = String(result.id);
    }
    if ('name' in result) {
      result.name = String(result.name);
    }
    if ('email' in result) {
      result.email = String(result.email);
    }
    return result;
  }
  function canInitRum(initConfiguration) {
    if (isAlreadyInitialized) {
      if (!initConfiguration.silentMultipleInit) {
        display.error('DD_RUM is already initialized.');
      }
      return false;
    }
    if (!initConfiguration || !initConfiguration.clientToken && !initConfiguration.publicApiKey) {
      display.error('Client Token is not configured, we will not send any data.');
      return false;
    }
    if (!initConfiguration.applicationId) {
      display.error('Application ID is not configured, no RUM data will be collected.');
      return false;
    }
    if (initConfiguration.sampleRate !== undefined && !isPercentage(initConfiguration.sampleRate)) {
      display.error('Sample Rate should be a number between 0 and 100');
      return false;
    }
    if (initConfiguration.resourceSampleRate !== undefined && !isPercentage(initConfiguration.resourceSampleRate)) {
      display.error('Resource Sample Rate should be a number between 0 and 100');
      return false;
    }
    if (Array.isArray(initConfiguration.allowedTracingOrigins) && initConfiguration.allowedTracingOrigins.length !== 0 && initConfiguration.service === undefined) {
      display.error('Service need to be configured when tracing is enabled');
      return false;
    }
    return true;
  }
}
