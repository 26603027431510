import { monitor, ONE_MINUTE, SESSION_TIME_OUT_DELAY, relativeNow } from '@datadog/browser-core';
import { LifeCycleEventType } from './lifeCycle';
export var VIEW_CONTEXT_TIME_OUT_DELAY = SESSION_TIME_OUT_DELAY;
export var ACTION_CONTEXT_TIME_OUT_DELAY = 5 * ONE_MINUTE; // arbitrary
export var CLEAR_OLD_CONTEXTS_INTERVAL = ONE_MINUTE;
export function startParentContexts(lifeCycle, session) {
  var currentView;
  var currentAction;
  var currentSessionId;
  var previousViews = [];
  var previousActions = [];
  lifeCycle.subscribe(LifeCycleEventType.VIEW_CREATED, function (currentContext) {
    currentView = currentContext;
    currentSessionId = session.getId();
  });
  lifeCycle.subscribe(LifeCycleEventType.VIEW_UPDATED, function (currentContext) {
    // A view can be updated after its end.  We have to ensure that the view being updated is the
    // most recently created.
    if (currentView && currentView.id === currentContext.id) {
      currentView = currentContext;
    }
  });
  lifeCycle.subscribe(LifeCycleEventType.VIEW_ENDED, function (_a) {
    var endClocks = _a.endClocks;
    if (currentView) {
      previousViews.unshift({
        endTime: endClocks.relative,
        context: buildCurrentViewContext(),
        startTime: currentView.startClocks.relative
      });
      currentView = undefined;
    }
  });
  lifeCycle.subscribe(LifeCycleEventType.AUTO_ACTION_CREATED, function (currentContext) {
    currentAction = currentContext;
  });
  lifeCycle.subscribe(LifeCycleEventType.AUTO_ACTION_COMPLETED, function (action) {
    if (currentAction) {
      previousActions.unshift({
        context: buildCurrentActionContext(),
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        endTime: currentAction.startClocks.relative + action.duration,
        startTime: currentAction.startClocks.relative
      });
    }
    currentAction = undefined;
  });
  lifeCycle.subscribe(LifeCycleEventType.AUTO_ACTION_DISCARDED, function () {
    currentAction = undefined;
  });
  lifeCycle.subscribe(LifeCycleEventType.SESSION_RENEWED, function () {
    previousViews = [];
    previousActions = [];
    currentView = undefined;
    currentAction = undefined;
  });
  var clearOldContextsInterval = setInterval(monitor(function () {
    clearOldContexts(previousViews, VIEW_CONTEXT_TIME_OUT_DELAY);
    clearOldContexts(previousActions, ACTION_CONTEXT_TIME_OUT_DELAY);
  }), CLEAR_OLD_CONTEXTS_INTERVAL);
  function clearOldContexts(previousContexts, timeOutDelay) {
    var oldTimeThreshold = relativeNow() - timeOutDelay;
    while (previousContexts.length > 0 && previousContexts[previousContexts.length - 1].startTime < oldTimeThreshold) {
      previousContexts.pop();
    }
  }
  function buildCurrentViewContext() {
    return {
      session: {
        id: currentSessionId
      },
      view: {
        id: currentView.id,
        name: currentView.name,
        referrer: currentView.referrer,
        url: currentView.location.href
      }
    };
  }
  function buildCurrentActionContext() {
    return {
      action: {
        id: currentAction.id
      }
    };
  }
  function findContext(buildContext, previousContexts, currentContext, startTime) {
    if (startTime === undefined) {
      return currentContext ? buildContext() : undefined;
    }
    if (currentContext && startTime >= currentContext.startClocks.relative) {
      return buildContext();
    }
    for (var _i = 0, previousContexts_1 = previousContexts; _i < previousContexts_1.length; _i++) {
      var previousContext = previousContexts_1[_i];
      if (startTime > previousContext.endTime) {
        break;
      }
      if (startTime >= previousContext.startTime) {
        return previousContext.context;
      }
    }
    return undefined;
  }
  return {
    findAction: function (startTime) {
      return findContext(buildCurrentActionContext, previousActions, currentAction, startTime);
    },
    findView: function (startTime) {
      return findContext(buildCurrentViewContext, previousViews, currentView, startTime);
    },
    stop: function () {
      clearInterval(clearOldContextsInterval);
    }
  };
}
