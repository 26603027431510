import { __assign } from "tslib";
import { elapsed, generateUUID, monitor, ONE_MINUTE, throttle, clocksNow, clocksOrigin, timeStampNow, display } from '@datadog/browser-core';
import { ViewLoadingType } from '../../../rawRumEvent.types';
import { LifeCycleEventType } from '../../lifeCycle';
import { trackInitialViewTimings } from './trackInitialViewTimings';
import { trackLocationChanges, areDifferentLocation } from './trackLocationChanges';
import { trackViewMetrics } from './trackViewMetrics';
export var THROTTLE_VIEW_UPDATE_PERIOD = 3000;
export var SESSION_KEEP_ALIVE_INTERVAL = 5 * ONE_MINUTE;
export function trackViews(location, lifeCycle, domMutationObservable, areViewsTrackedAutomatically, initialViewName) {
  var isRecording = false;
  var _a = trackInitialView(initialViewName),
    stopInitialViewTracking = _a.stop,
    initialView = _a.initialView;
  var currentView = initialView;
  var stopViewLifeCycle = startViewLifeCycle().stop;
  var stopViewCollectionMode = (areViewsTrackedAutomatically ? startAutomaticViewCollection() : startManualViewCollection()).stop;
  function trackInitialView(name) {
    var initialView = newView(lifeCycle, domMutationObservable, location, isRecording, ViewLoadingType.INITIAL_LOAD, document.referrer, clocksOrigin(), name);
    var stop = trackInitialViewTimings(lifeCycle, function (timings) {
      initialView.updateTimings(timings);
      initialView.scheduleUpdate();
    }).stop;
    return {
      initialView: initialView,
      stop: stop
    };
  }
  function trackViewChange(startClocks, name) {
    return newView(lifeCycle, domMutationObservable, location, isRecording, ViewLoadingType.ROUTE_CHANGE, currentView.url, startClocks, name);
  }
  function startViewLifeCycle() {
    lifeCycle.subscribe(LifeCycleEventType.SESSION_RENEWED, function () {
      // do not trigger view update to avoid wrong data
      currentView.end();
      // Renew view on session renewal
      currentView = trackViewChange(undefined, currentView.name);
    });
    // End the current view on page unload
    lifeCycle.subscribe(LifeCycleEventType.BEFORE_UNLOAD, function () {
      currentView.end();
      currentView.triggerUpdate();
    });
    lifeCycle.subscribe(LifeCycleEventType.RECORD_STARTED, function () {
      isRecording = true;
      currentView.updateHasReplay(true);
    });
    lifeCycle.subscribe(LifeCycleEventType.RECORD_STOPPED, function () {
      isRecording = false;
    });
    // Session keep alive
    var keepAliveInterval = window.setInterval(monitor(function () {
      currentView.triggerUpdate();
    }), SESSION_KEEP_ALIVE_INTERVAL);
    return {
      stop: function () {
        clearInterval(keepAliveInterval);
      }
    };
  }
  function startAutomaticViewCollection() {
    return trackLocationChanges(function () {
      if (areDifferentLocation(currentView.getLocation(), location)) {
        // Renew view on location changes
        currentView.end();
        currentView.triggerUpdate();
        currentView = trackViewChange();
        return;
      }
      currentView.updateLocation(location);
      currentView.triggerUpdate();
    });
  }
  function startManualViewCollection() {
    return trackLocationChanges(function () {
      currentView.updateLocation(location);
      currentView.triggerUpdate();
    });
  }
  return {
    addTiming: function (name, time) {
      if (time === void 0) {
        time = timeStampNow();
      }
      currentView.addTiming(name, time);
      currentView.triggerUpdate();
    },
    startView: function (name, startClocks) {
      currentView.end(startClocks);
      currentView.triggerUpdate();
      currentView = trackViewChange(startClocks, name);
    },
    stop: function () {
      stopViewCollectionMode();
      stopInitialViewTracking();
      stopViewLifeCycle();
      currentView.end();
    }
  };
}
function newView(lifeCycle, domMutationObservable, initialLocation, initialHasReplay, loadingType, referrer, startClocks, name) {
  if (startClocks === void 0) {
    startClocks = clocksNow();
  }
  // Setup initial values
  var id = generateUUID();
  var timings = {};
  var customTimings = {};
  var documentVersion = 0;
  var endClocks;
  var location = __assign({}, initialLocation);
  var hasReplay = initialHasReplay;
  lifeCycle.notify(LifeCycleEventType.VIEW_CREATED, {
    id: id,
    name: name,
    startClocks: startClocks,
    location: location,
    referrer: referrer
  });
  // Update the view every time the measures are changing
  var _a = throttle(monitor(triggerViewUpdate), THROTTLE_VIEW_UPDATE_PERIOD, {
      leading: false
    }),
    scheduleViewUpdate = _a.throttled,
    cancelScheduleViewUpdate = _a.cancel;
  var _b = trackViewMetrics(lifeCycle, domMutationObservable, scheduleViewUpdate, loadingType),
    setLoadEvent = _b.setLoadEvent,
    stopViewMetricsTracking = _b.stop,
    viewMetrics = _b.viewMetrics;
  // Initial view update
  triggerViewUpdate();
  function triggerViewUpdate() {
    documentVersion += 1;
    var currentEnd = endClocks === undefined ? timeStampNow() : endClocks.timeStamp;
    lifeCycle.notify(LifeCycleEventType.VIEW_UPDATED, __assign(__assign({}, viewMetrics), {
      customTimings: customTimings,
      documentVersion: documentVersion,
      id: id,
      name: name,
      loadingType: loadingType,
      location: location,
      hasReplay: hasReplay,
      referrer: referrer,
      startClocks: startClocks,
      timings: timings,
      duration: elapsed(startClocks.timeStamp, currentEnd),
      isActive: endClocks === undefined
    }));
  }
  return {
    name: name,
    scheduleUpdate: scheduleViewUpdate,
    end: function (clocks) {
      if (clocks === void 0) {
        clocks = clocksNow();
      }
      endClocks = clocks;
      stopViewMetricsTracking();
      lifeCycle.notify(LifeCycleEventType.VIEW_ENDED, {
        endClocks: endClocks
      });
    },
    getLocation: function () {
      return location;
    },
    triggerUpdate: function () {
      // cancel any pending view updates execution
      cancelScheduleViewUpdate();
      triggerViewUpdate();
    },
    updateTimings: function (newTimings) {
      timings = newTimings;
      if (newTimings.loadEvent !== undefined) {
        setLoadEvent(newTimings.loadEvent);
      }
    },
    addTiming: function (name, time) {
      customTimings[sanitizeTiming(name)] = elapsed(startClocks.timeStamp, time);
    },
    updateLocation: function (newLocation) {
      location = __assign({}, newLocation);
    },
    updateHasReplay: function (newHasReplay) {
      hasReplay = newHasReplay;
    },
    get url() {
      return location.href;
    }
  };
}
/**
 * Timing name is used as facet path that must contain only letters, digits, or the characters - _ . @ $
 */
function sanitizeTiming(name) {
  var sanitized = name.replace(/[^a-zA-Z0-9-_.@$]/g, '_');
  if (sanitized !== name) {
    display.warn("Invalid timing name: " + name + ", sanitized to: " + sanitized);
  }
  return sanitized;
}
