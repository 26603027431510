import { __assign } from "tslib";
import { getCurrentSite } from '../browser/cookie';
import { catchUserErrors } from '../tools/catchUserErrors';
import { includes, ONE_KILO_BYTE, ONE_SECOND } from '../tools/utils';
import { computeTransportConfiguration } from './transportConfiguration';
export var DEFAULT_CONFIGURATION = {
  allowedTracingOrigins: [],
  maxErrorsByMinute: 3000,
  maxInternalMonitoringMessagesPerPage: 15,
  resourceSampleRate: 100,
  sampleRate: 100,
  silentMultipleInit: false,
  trackInteractions: false,
  trackViewsManually: false,
  /**
   * arbitrary value, byte precision not needed
   */
  requestErrorResponseLengthLimit: 32 * ONE_KILO_BYTE,
  /**
   * flush automatically, aim to be lower than ALB connection timeout
   * to maximize connection reuse.
   */
  flushTimeout: 30 * ONE_SECOND,
  /**
   * Logs intake limit
   */
  maxBatchSize: 50,
  maxMessageSize: 256 * ONE_KILO_BYTE,
  /**
   * beacon payload max queue size implementation is 64kb
   * ensure that we leave room for logs, rum and potential other users
   */
  batchBytesLimit: 16 * ONE_KILO_BYTE
};
export function buildConfiguration(initConfiguration, buildEnv) {
  var enableExperimentalFeatures = Array.isArray(initConfiguration.enableExperimentalFeatures) ? initConfiguration.enableExperimentalFeatures : [];
  var configuration = __assign(__assign({
    beforeSend: initConfiguration.beforeSend && catchUserErrors(initConfiguration.beforeSend, 'beforeSend threw an error:'),
    cookieOptions: buildCookieOptions(initConfiguration),
    isEnabled: function (feature) {
      return includes(enableExperimentalFeatures, feature);
    },
    service: initConfiguration.service
  }, computeTransportConfiguration(initConfiguration, buildEnv)), DEFAULT_CONFIGURATION);
  if ('allowedTracingOrigins' in initConfiguration) {
    configuration.allowedTracingOrigins = initConfiguration.allowedTracingOrigins;
  }
  if ('sampleRate' in initConfiguration) {
    configuration.sampleRate = initConfiguration.sampleRate;
  }
  if ('resourceSampleRate' in initConfiguration) {
    configuration.resourceSampleRate = initConfiguration.resourceSampleRate;
  }
  if ('trackInteractions' in initConfiguration) {
    configuration.trackInteractions = !!initConfiguration.trackInteractions;
  }
  if ('trackViewsManually' in initConfiguration) {
    configuration.trackViewsManually = !!initConfiguration.trackViewsManually;
  }
  if ('actionNameAttribute' in initConfiguration) {
    configuration.actionNameAttribute = initConfiguration.actionNameAttribute;
  }
  return configuration;
}
export function buildCookieOptions(initConfiguration) {
  var cookieOptions = {};
  cookieOptions.secure = mustUseSecureCookie(initConfiguration);
  cookieOptions.crossSite = !!initConfiguration.useCrossSiteSessionCookie;
  if (!!initConfiguration.trackSessionAcrossSubdomains) {
    cookieOptions.domain = getCurrentSite();
  }
  return cookieOptions;
}
function mustUseSecureCookie(initConfiguration) {
  return !!initConfiguration.useSecureSessionCookie || !!initConfiguration.useCrossSiteSessionCookie;
}
