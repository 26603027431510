import { __assign } from "tslib";
import { callMonitored, monitor } from '../domain/internalMonitoring';
import { elapsed, relativeNow, clocksNow, timeStampNow } from '../tools/timeUtils';
import { normalizeUrl } from '../tools/urlPolyfill';
var xhrProxySingleton;
var beforeSendCallbacks = [];
var onRequestCompleteCallbacks = [];
var originalXhrOpen;
var originalXhrSend;
var originalXhrAbort;
export function startXhrProxy() {
  if (!xhrProxySingleton) {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    originalXhrOpen = XMLHttpRequest.prototype.open;
    // eslint-disable-next-line @typescript-eslint/unbound-method
    originalXhrSend = XMLHttpRequest.prototype.send;
    // eslint-disable-next-line @typescript-eslint/unbound-method
    originalXhrAbort = XMLHttpRequest.prototype.abort;
    XMLHttpRequest.prototype.open = openXhr;
    XMLHttpRequest.prototype.send = sendXhr;
    XMLHttpRequest.prototype.abort = abortXhr;
    xhrProxySingleton = {
      beforeSend: function (callback) {
        beforeSendCallbacks.push(callback);
      },
      onRequestComplete: function (callback) {
        onRequestCompleteCallbacks.push(callback);
      }
    };
  }
  return xhrProxySingleton;
}
export function resetXhrProxy() {
  if (xhrProxySingleton) {
    xhrProxySingleton = undefined;
    beforeSendCallbacks.length = 0;
    onRequestCompleteCallbacks.length = 0;
    XMLHttpRequest.prototype.open = originalXhrOpen;
    XMLHttpRequest.prototype.send = originalXhrSend;
    XMLHttpRequest.prototype.abort = originalXhrAbort;
  }
}
function openXhr(method, url) {
  var _this = this;
  callMonitored(function () {
    // WARN: since this data structure is tied to the instance, it is shared by both logs and rum
    // and can be used by different code versions depending on customer setup
    // so it should stay compatible with older versions
    _this._datadog_xhr = {
      method: method,
      url: normalizeUrl(url)
    };
  });
  return originalXhrOpen.apply(this, arguments);
}
function sendXhr() {
  var _this = this;
  callMonitored(function () {
    if (!_this._datadog_xhr) {
      return;
    }
    _this._datadog_xhr.startTime = relativeNow();
    _this._datadog_xhr.startClocks = clocksNow();
    _this._datadog_xhr.isAborted = false;
    var hasBeenReported = false;
    var originalOnreadystatechange = _this.onreadystatechange;
    var onreadystatechange = function () {
      if (this.readyState === XMLHttpRequest.DONE) {
        // Try to report the XHR as soon as possible, because the XHR may be mutated by the
        // application during a future event. For example, Angular is calling .abort() on
        // completed requests during a onreadystatechange event, so the status becomes '0'
        // before the request is collected.
        onEnd();
      }
      if (originalOnreadystatechange) {
        originalOnreadystatechange.apply(this, arguments);
      }
    };
    var onEnd = monitor(function () {
      _this.removeEventListener('loadend', onEnd);
      // if the onreadystatechange hasn't been overridden by the user after the send()
      if (_this.onreadystatechange === onreadystatechange) {
        _this.onreadystatechange = originalOnreadystatechange;
      }
      if (hasBeenReported) {
        return;
      }
      hasBeenReported = true;
      reportXhr(_this);
    });
    _this.onreadystatechange = onreadystatechange;
    _this.addEventListener('loadend', onEnd);
    beforeSendCallbacks.forEach(function (callback) {
      return callback(_this._datadog_xhr, _this);
    });
  });
  return originalXhrSend.apply(this, arguments);
}
function abortXhr() {
  var _this = this;
  callMonitored(function () {
    if (_this._datadog_xhr) {
      _this._datadog_xhr.isAborted = true;
    }
  });
  return originalXhrAbort.apply(this, arguments);
}
function reportXhr(xhr) {
  xhr._datadog_xhr.duration = elapsed(xhr._datadog_xhr.startClocks.timeStamp, timeStampNow());
  xhr._datadog_xhr.responseText = xhr.response;
  xhr._datadog_xhr.status = xhr.status;
  xhr._datadog_xhr.xhr = xhr;
  onRequestCompleteCallbacks.forEach(function (callback) {
    return callback(__assign({}, xhr._datadog_xhr));
  });
}
