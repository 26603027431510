var Observable = /** @class */function () {
  function Observable() {
    this.observers = [];
  }
  Observable.prototype.subscribe = function (f) {
    var _this = this;
    this.observers.push(f);
    return {
      unsubscribe: function () {
        _this.observers = _this.observers.filter(function (other) {
          return f !== other;
        });
      }
    };
  };
  Observable.prototype.notify = function (data) {
    this.observers.forEach(function (observer) {
      return observer(data);
    });
  };
  return Observable;
}();
export { Observable };
