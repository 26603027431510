import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { UIModule } from './ui.module';

import { AppComponent } from './app.component';
import { InitialLoadService } from './services/initial-load.service';
import {
  ApiService,
  AuthenticationService,
  TitleService,
} from '@wilson/wilsonng';
import { ThemeChooserDirective } from './directives/theme-chooser.directive';
import { AppRoutingModule } from './app-routing.module';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommunitiesInfoInterceptor } from './interceptors/communities-info.interceptor';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { DatePipe } from '@angular/common';
import { FiltersService } from './services/filters.service';

@NgModule({
  declarations: [
    // Directives
    ThemeChooserDirective,

    // Components
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    DragDropModule,
    RouterModule.forRoot([], {
      scrollPositionRestoration: 'enabled',
      initialNavigation: 'disabled',
      paramsInheritanceStrategy: 'always',
    }),
    AppRoutingModule,
    UIModule,
  ],
  providers: [
    InitialLoadService,
    MessageService,
    ConfirmationService,
    DatePipe,
    ApiService,
    TitleService,
    {
      provide: 'productName',
      useValue: 'Communities',
    },
    {
      provide: 'baseApiUrl',
      useValue: document.getElementsByTagName('base')[0].href,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CommunitiesInfoInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: (ils: InitialLoadService) => () => ils.getInitialLoad(),
      deps: [InitialLoadService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (as: AuthenticationService) => () => as.getUser(),
      deps: [AuthenticationService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (fs: FiltersService) => () => fs.getUnitsFilter(),
      deps: [FiltersService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
