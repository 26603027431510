import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FunnelService } from 'src/app/services/funnel.service';
import { UserService } from 'src/app/services/user.service';
@Injectable()
export class CommunitiesInfoInterceptor implements HttpInterceptor {
  constructor(
    private funnelService: FunnelService,
    private userService: UserService
  ) {}
  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // No need to attach communities-info header in this case
    if (req.url.startsWith('https://vimeo.com')) {
      return next.handle(req.clone());
    }
    const currentFunnel = this.funnelService.getCurrentFunnel();
    const user = this.userService.user;
    // Create and attach custom header
    const communitiesInfo = {
      currentProgram: currentFunnel?.id || user?.lastViewedProgramId,
    };
    const reqWithCustomHeader = req.clone({
      headers: req.headers.set(
        'communities-info',
        JSON.stringify(communitiesInfo)
      ),
    });
    return next.handle(reqWithCustomHeader);
  }
}
