<div appThemeChooser id="app" *ngIf="funnelsLoaded">
  <router-outlet></router-outlet>

  <!-- custom toast notifications -->
  <p-toast position="bottom-center">
    <ng-template let-message pTemplate="message">
      <div>
        <h5 *ngIf="message.detail">{{ message.summary }}</h5>
        <p [innerHTML]="message.detail || message.summary"></p>
      </div>
    </ng-template>
  </p-toast>

  <!-- custom confirmation dialog -->
  <p-confirmDialog
    [modal]="true"
    rejectButtonStyleClass="p-button-text p-button-secondary"
    acceptButtonStyleClass="p-button-primary"
  ></p-confirmDialog>
</div>

<wilson-spinner></wilson-spinner>

<div class="loadingMessage" *ngIf="isLoading">Loading...</div>
