import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { HttpErrorService } from '../services/http-error.service';

@Injectable()
export class HttpErrorInterceptor {
  constructor(
    private router: Router,
    private httpErrorService: HttpErrorService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (!this.httpErrorService.pHttpErrorInterceptorOn.value) {
          return;
        }

        if (err.status === 401 || err.status === 403) {
          if (err.error.length) {
            this.router.navigate(['p/forbidden'], {
              state: { message: err.error },
            });
          } else {
            this.router.navigate(['p/forbidden']);
          }
        } else if (err.status === 404) {
          if (err.error.length) {
            this.router.navigate(['p/notfound'], {
              state: { message: err.error, redirect: 'none' },
            });
          } else {
            this.router.navigate(['p/notfound']);
          }
        }
        return throwError(err);
      })
    );
  }
}
