import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RouterNavigationService } from '@wilson/wilsonng';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root',
})
export class AppInitService {
  constructor(
    private logService: LogService,
    private routerNavigationService: RouterNavigationService,
    private router: Router
  ) {}

  init(): void {
    this.logService.setupDatadogMonitoring();
  }

  initRouting(): void {
    this.routerNavigationService.startTrackingPreviousUrl();
    this.router.initialNavigation();
  }
}
