import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HubLandingComponent } from './pages/hub-landing/hub-landing.component';

const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () =>
      import('./funnels/admin/admin.module').then((m) => m.AdminModule),
  },
  {
    // p = 'public'
    path: 'p',
    loadChildren: () =>
      import('./funnels/unauthed/unauthed.module').then(
        (m) => m.UnauthedModule
      ),
  },
  {
    path: 'funhub',
    component: HubLandingComponent,
    data: {
      useHubLandingHeader: true,
    },
  },
  {
    path: '',
    loadChildren: () =>
      import('./funnels/client/client.module').then((m) => m.ClientModule),
  },
  { path: '**', redirectTo: 'p/notfound', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
